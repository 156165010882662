export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAIL = "CHECK_EMAIL_FAIL";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const USER_OFFERS_SUCCESS = "USER_OFFERS_SUCCESS";
export const USER_OFFERS_FAIL = "USER_OFFERS_FAIL";

export const OFFER_DETAILS_SUCCESS = "OFFER_DETAILS_SUCCESS";
export const OFFER_DETAILS_FAIL = "OFFER_DETAILS_FAIL";

export const CATEGORIES_DETAILS_SUCCESS = "CATEGORIES_DETAILS_SUCCESS";
export const CATEGORIES_DETAILS_FAIL = "CATEGORIES_DETAILS_FAIL";

export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAIL = "DELETE_OFFER_FAIL";
